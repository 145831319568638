import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StockInputDto } from 'src/app/swagger';
import { OrderStoredPacksShortListComponent } from './order-stored-packs-short-list/order-stored-packs-short-list.component';
import { OrderDialogService } from '../order-dialog.service';

@Component({
    selector: 'app-storerx-dialog',
    template: `
		<div class="mb-2 font-bold">
			{{ 'ORDERS.ORDEROVERVIEW.CANCEL.LABELS.IN_STORERX' | translate }}
		</div>
		<div>
			@if (isAdapterConnected) {
				<div>
					{{
						'ORDERS.ORDEROVERVIEW.CANCEL.REMOVE_FROM_STORERX'
							| translate: { n: stockItemsNo }
					}}
				</div>
			}
			<app-stored-packs-short-list
				[shelves]="stockInputs ?? []"
				[forDialog]="true"
			/>
		</div>
	`,
    styles: [],
    imports: [OrderStoredPacksShortListComponent, TranslateModule]
})
export class StorerxDialogComponent implements OnInit {
	@Input() isAdapterConnected: boolean;
	@Input() stockInputs: StockInputDto[] = [];
	stockItemsNo: number = 0;

	constructor(private dialogService: OrderDialogService) {}

	public ngOnInit(): void {
		this.stockItemsNo = this.dialogService.get_stockinputs_count(
			this.stockInputs,
		);
	}
}
