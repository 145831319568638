import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GetOrderResponse } from 'src/app/swagger';
import { CensorNamePipe } from '../../../pipes/censor.pipe';
import { DisplayHyphenIfNull } from '../../../pipes/display-hyphen-if-null.pipe';
import { MaxLengthEllipsisPipe } from '../../../pipes/max-length-ellipsis.pipe';
import { IconsComponent } from '../../icons/icons.component';
import { CopyClipboardDirective } from 'src/app/core/directives/copy-clipboard.directive';
import { ToastService } from '../../toast/toast.service';
import { IToast } from 'src/app/core/interfaces/toast-helper.interface';
import { ToastType } from 'src/app/core/enums/toast-type.enum';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    imports: [
        TranslateModule,
        MaxLengthEllipsisPipe,
        DisplayHyphenIfNull,
        CensorNamePipe,
        IconsComponent,
        CopyClipboardDirective,
    ]
})
export class OrderDetailsComponent {
	@Input() order: GetOrderResponse;
	@Input() isSuperAdmin: boolean;
	copied: boolean = false;

	constructor(private toast: ToastService) {}

	async copy() {
		this.copied = true;
		this.toast.makeToast({
			title: 'ORDERS.ORDEROVERVIEW.COPY.LABEL',
			showIcon: true,
			type: ToastType.SUCCESS,
			durationMilli: 3000,
		} as IToast);
		setTimeout(() => (this.copied = false), 1000);
	}
}
