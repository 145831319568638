import { DatePipe } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	Output,
	Pipe,
	PipeTransform,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import {
	GetOrderDto,
	GetOrderResponse,
	ORDERSTATE,
	SetReadyForInputOrderRequest,
} from 'src/app/swagger';
import { OrderStateColorDirective } from 'src/app/core/directives/order-state-color.directive';
import { OrderStatusTranslateLabelPipe } from 'src/app/core/pipes/order-status-translate-label.pipe';
import {
	DemoDisplayDirective,
	NormalDisplayDirective,
} from 'src/app/core/services/state.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import { AppTooltipDirective } from '../../../directives/app-tooltip.directive';
import { ConvertDateToLocalPipe } from '../../../pipes/convert-date-to-local.pipe';
import { DateRelativeDisplayPipe } from '../../../pipes/date-relative-display.pipe';
import { IconsComponent } from '../../icons/icons.component';
import { ContactDetailsComponent } from '../contact-details/contact-details.component';
import { OrderActionComponent } from '../order-action/order-action.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { PrintService } from '../../../services/print.service';
import { DynamicLocaleService } from 'src/app/core/services/dynamic-locale.service';

@Component({
    selector: 'app-scheduled-order-overview',
    templateUrl: './scheduled-order-overview.component.html',
    styles: [],
    imports: [
        IconsComponent,
        OrderDetailsComponent,
        ContactDetailsComponent,
        AppTooltipDirective,
        OrderActionComponent,
        DatePipe,
        TranslateModule,
        DateRelativeDisplayPipe,
        ConvertDateToLocalPipe,
        DemoDisplayDirective,
        NormalDisplayDirective,
        OrderStateColorDirective,
        OrderStatusTranslateLabelPipe,
    ]
})
export class ScheduledOrderOverviewComponent {
	@Input() order: GetOrderDto;
	@Input() isOrderDetails: boolean = false;
	@Output() printOrder: EventEmitter<any> = new EventEmitter();

	locale: string;
	amountOfPackages: number = 0;

	constructor(
		private printService: PrintService,
		private swagger: SwaggerService,
		private router: Router,
		private dynamicLocale: DynamicLocaleService,
	) {
		this.locale = this.dynamicLocale.getCurRegionSetInStorage();
	}

	printPickup(order: GetOrderResponse, event) {
		if (event != null) {
			event.stopPropagation();
		}

		this.printService.curPickupOrder.next(order);
	}

	async readyforinput(order: GetOrderResponse, event) {
		if (event != null) {
			event.stopPropagation();
		}

		await lastValueFrom(
			this.swagger.demo.apiDemoReadyforinputPost({
				storageCode: order.storageCode,
			} as SetReadyForInputOrderRequest),
		);

		this.router.navigate(['orders']);
	}
}
