import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconsComponent } from 'src/app/core/components/icons/icons.component';
import { OutputLocation } from 'src/app/swagger';
import { RowaDropdownsModule } from '../../../../../../libs/ui/src/lib/dropdowns/dropdowns.module';
import { ImportedIconsComponent } from '../../../../core/components/imported-icons/imported-icons.component';

@Component({
	selector: 'app-vmax-dialog',
	template: `
		@if (isAdapterConnected && storedQ > 0) {
			<div class="flex my-4 gap-x-4">
				<div class="flex-none">
					<app-imported-icons
						[iconName]="'orders.cancel.warningLight'"
					/>
				</div>
				<div class="flex-grow">
					@if (isDemoOrder) {
						<span>
							{{
								'ORDERS.ORDEROVERVIEW.DISPENSE.DEMO_ORDER'
									| translate
							}}
						</span>
					}
					@if (!isRecurrentOrder) {
						<span>
							{{
								'ORDERS.ORDEROVERVIEW.DISPENSE.ALL_PACKAGES_WILL_BE_DISPENSED_FROM_THE_ROBOT'
									| translate
							}}
						</span>
					} @else {
						<span>
							{{
								'ORDERS.ORDEROVERVIEW.CANCEL.PACKAGES_UNRESERVED'
									| translate
							}}
						</span>
					}
				</div>
			</div>
		}
		<div class="flex flex-col gap-2">
			<div class="font-bold">
				{{ 'ORDERS.ORDEROVERVIEW.CANCEL.LABELS.IN_VMAX' | translate }}
			</div>
			<div>
				<div class="text-xs font-normal text-g-3-600">
					{{ 'ORDERS.ORDEROVERVIEW.PACKAGES.LABEL' | translate }}
				</div>
				<div>
					{{
						storedQ > 0
							? storedQ +
								' ' +
								('ORDERS.ORDEROVERVIEW.PACKAGES.STORED'
									| translate)
							: ''
					}}
					@if (storedQ > 0) {
						<br />
					}
					{{
						dispensedQ > 0
							? dispensedQ +
								' ' +
								('ORDERS.ORDEROVERVIEW.PACKAGES.DISPENSED'
									| translate)
							: ''
					}}
				</div>
			</div>
			@if (!isRecurrentOrder) {
				<div>
					<div class="mb-2 font-bold">
						{{
							'ORDERS.ORDEROVERVIEW.DISPENSE.OUTPUT_DESTINATION'
								| translate
						}}
					</div>
					<div class="h-8">
						<rowa-dropdown-list
							required
							class="w-100"
							[items]="outputLocations"
							[(ngModel)]="selectedOutputLocation"
							(ngModelChange)="
								selectedOutputLocationChange.emit(
									selectedOutputLocation
								)
							"
							[placeholder]="
								'ORDERS.ORDEROVERVIEW.DISPENSE.CHOOSE'
									| translate
							"
							[options]="{ hideLabel: true }"
							[disabled]="!isAdapterConnected || storedQ === 0"
						>
							<ng-template #selected let-selected>
								<span>
									{{ !selected ? '' : selected.name }}
								</span>
							</ng-template>

							<ng-template #option let-option>
								<span>{{ option.name }}</span>
							</ng-template>
						</rowa-dropdown-list>
					</div>
				</div>
			}
			@if (outputLocations?.length === 0) {
				<div
					class="flex flex-row pt-4 gap-x-4 hover:text-bl-1-400 hover:cursor-pointer hover:underline text-s-3-500"
					(click)="gotooutput.emit()"
				>
					<div>
						{{
							'ORDERS.ORDEROVERVIEW.DISPENSE.NO_OUTPUT_DESTINATIONS'
								| translate
						}}
					</div>
					<div>
						<app-icons iconName="faArrowRight" />
					</div>
				</div>
			}
		</div>
	`,
	styles: [],
	imports: [
		ImportedIconsComponent,
		RowaDropdownsModule,
		FormsModule,
		TranslateModule,
		IconsComponent,
	],
})
export class VmaxDialogComponent {
	@Input() isAdapterConnected: boolean;
	@Input() orderNumber: string = '';
	@Input() isRecurrentOrder: boolean = false;
	@Input() storedQ: number = 0;
	@Input() dispensedQ: number;
	@Input() selectedOutputLocation: OutputLocation;
	@Input() outputLocations: OutputLocation[];
	@Output() selectedOutputLocationChange = new EventEmitter<OutputLocation>();
	@Output() gotooutput = new EventEmitter();
	public isDemoOrder: boolean = false;
	constructor() {}

	ngOnInit(): void {
		this.isDemoOrder = this.orderNumber.toLowerCase().startsWith('demo');
	}
}
