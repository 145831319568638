import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RowaActivityIndicatorModule } from '@rowa/ui';
import { Observable, lastValueFrom, of, tap } from 'rxjs';
import { DialogRef } from 'src/app/core/components/dialog/dialog-ref';
import { getCancellationReasons } from 'src/app/core/model/cancellation-reason.model';
import { CancellationReasonTranslateLabelPipe } from 'src/app/core/pipes/cancellation-reason-translate-label.pipe';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import { UserService } from 'src/app/core/services/user.service';
import {
	GetOrderDto,
	ORDERSTATE,
	OrderDetailsDto,
	OutputLocation,
	PickupOutputState,
} from 'src/app/swagger/model/models';
import { RowaCheckboxModule } from '../../../../../../libs/ui/src/lib/checkbox/checkbox.module';
import { RowaDropdownsModule } from '../../../../../../libs/ui/src/lib/dropdowns/dropdowns.module';
import { ConfirmDialogComponent } from '../../../../core/components/dialog/confirm-dialog/confirm-dialog.component';
import { NotifyToastComponent } from '../../../../core/components/notify-toast/notify-toast.component';
import {
	CensorEmailPipe,
	CensorPhonePipe,
} from '../../../../core/pipes/censor.pipe';
import { DisplayHyphenIfNull } from '../../../../core/pipes/display-hyphen-if-null.pipe';
import { ConnectionNotificationComponent } from '../connection-notification/connection-notification.component';
import { StorerxDialogComponent } from '../storerx-dialog/storerx-dialog.component';
import { VmaxDialogComponent } from '../vmax-dialog/vmax-dialog.component';
import { MODE, OrderDialogService } from './../order-dialog.service';
import { ButtonComponent } from 'src/app/core/components/button.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cancel-order-dialog',
    templateUrl: './cancel-order-dialog.component.html',
    styles: [],
    imports: [
        ConfirmDialogComponent,
        ConnectionNotificationComponent,
        RowaDropdownsModule,
        FormsModule,
        VmaxDialogComponent,
        StorerxDialogComponent,
        RowaCheckboxModule,
        NotifyToastComponent,
        AsyncPipe,
        TranslateModule,
        DisplayHyphenIfNull,
        CensorPhonePipe,
        CensorEmailPipe,
        CancellationReasonTranslateLabelPipe,
        RowaActivityIndicatorModule,
        ButtonComponent,
    ]
})
export class CancelOrderDialogComponent implements OnInit {
	@Input({ required: true }) overviewOrder: GetOrderDto;
	order: OrderDetailsDto;
	notifyCustomer: boolean = true;
	cancellationReasons = [];
	getOutputLocations$: Observable<Array<OutputLocation>>;
	selectedOutputLocation: OutputLocation;
	isSuperAdmin: boolean = false;
	isAdapterConnected$: Observable<boolean>;
	mode$: Observable<MODE>;
	vmaxStored: number = 0;

	constructor(
		private dialogRef: DialogRef,
		private swagger: SwaggerService,
		private localStorage: LocalStorageService,
		private userService: UserService,
		private dialogService: OrderDialogService,
		private router: Router,
	) {
		this.isAdapterConnected$ =
			this.dialogService.isAdapterConnected$.asObservable();
		this.mode$ = this.dialogService.mode$.asObservable();
	}

	async ngOnInit() {
		this.cancellationReasons = getCancellationReasons();
		this.order = await lastValueFrom(
			this.swagger.OrderServiceAPI.apiOrderGetStoragecodeGet(
				this.overviewOrder.storageCode,
			),
		);

		this.order.sendCancellationEmail =
			this.order?.customerDetails?.email != null &&
			this.order?.customerDetails?.email != '';

		if (
			this.order.state === ORDERSTATE.Readyforpickup ||
			this.order.state === ORDERSTATE.Canceled
		) {
			this.getOutputLocations$ = this.getOutputLocations();
		}

		this.isSuperAdmin = this.userService.isSuperAdmin$.getValue();

		this.dialogService.get_dialog_mode(this.order);
		this.dialogService.is_adapter_connected();
		this.vmaxStored = this.order.articles.filter(
			(x) => x.state === PickupOutputState.None,
		).length;
	}

	public cancelOrder() {
		if (
			this.order.state === ORDERSTATE.Readyforpickup &&
			this.selectedOutputLocation
		) {
			this.localStorage.setItem(
				'localCancelOutput',
				this.selectedOutputLocation,
			);
		}

		this.dialogRef.confirm({
			order: this.order,
			outputDest: this.selectedOutputLocation,
			mode: this.dialogService.mode$.getValue(),
		});
	}

	close() {
		this.dialogRef.close();
	}

	gotoOutput() {
		this.close();
		this.router.navigate(['workstationsettings']);
	}

	getOutputLocations(): Observable<Array<OutputLocation>> {
		return this.dialogService.get_output_locations().pipe(
			tap((data: Array<OutputLocation>) => {
				this.selectedOutputLocation =
					this.dialogService.get_stored_outputlocation(
						'localCancelOutput',
						data,
					);
			}),
		);
	}
}
