import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StockInputDto } from 'src/app/swagger';

@Component({
	selector: 'app-stored-packs-short-list',
	imports: [NgTemplateOutlet, TranslateModule],
	template: `
		@for (shelf of shelves; track shelf.id) {
			<span class="block">
				<ng-container
					[ngTemplateOutlet]="
						forDialog ? itemTemplateDialog : itemTemplate
					"
				></ng-container>

				<ng-template #itemTemplate>
					<div
						class="w-24 overflow-hidden whitespace-nowrap text-ellipsis"
					>
						<span>
							{{ shelf.packagesAmount }}
						</span>
						x
						<span [title]="shelf.stock?.name">
							{{ shelf.stock?.name }}
						</span>
					</div>
				</ng-template>

				<ng-template #itemTemplateDialog>
					<span
						[innerHTML]="
							'AMOUNT_FROM_STOCKLOCATION'
								| translate
									: {
											amount: shelf.packagesAmount,
											stocklocation: shelf.stock?.name,
									  }
						"
					>
					</span>
				</ng-template>
			</span>
		}
	`,
})
export class OrderStoredPacksShortListComponent {
	@Input() shelves: StockInputDto[];
	@Input() forDialog: boolean;
}
