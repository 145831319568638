import { Component } from '@angular/core';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { IDisplayMessage } from 'src/app/core/interfaces/display-message.interface';
import { DisplayMessageComponent } from '../../../../core/components/display-message/display-message.component';

@Component({
    selector: 'app-connection-notification',
    template: `
		<app-display-message [message]="displayMSG"></app-display-message>
	`,
    styles: [],
    imports: [DisplayMessageComponent]
})
export class ConnectionNotificationComponent {
	public displayMSG = <IDisplayMessage>{
		title: 'VMAX.OFFLINE.TITLE',
		description: 'VMAX.OFFLINE.DESCRIPTION',
		showIcon: true,
		type: ToastType.WARNING,
		closeable: false,
	};
}
