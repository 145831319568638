import { Component, Input } from '@angular/core';
import { GetOrderResponse } from 'src/app/swagger';
import {
	CensorCityPipe,
	CensorEmailPipe,
	CensorPostalCodePipe,
	CensorStreetPipe,
} from '../../../pipes/censor.pipe';
import { DisplayHyphenIfNull } from '../../../pipes/display-hyphen-if-null.pipe';
import { MaxLengthEllipsisPipe } from '../../../pipes/max-length-ellipsis.pipe';

@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    imports: [
        MaxLengthEllipsisPipe,
        DisplayHyphenIfNull,
        CensorStreetPipe,
        CensorPostalCodePipe,
        CensorCityPipe,
        CensorEmailPipe,
    ]
})
export class ContactDetailsComponent {
	@Input() order: GetOrderResponse;
	@Input() isSuperAdmin: boolean;
}
